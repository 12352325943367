import { graphql } from "gatsby";
import * as React from "react";
import { MainContainer, PageContainer } from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import { usePageMetadata } from "../metadata/page";

// TODO: 等noise给文案
const PrivacyPolicyPage = () => {
  const { privacyPolicy } = usePageMetadata();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...privacyPolicy} />
      <MainContainer className="mb-4 pt-16 pb-16">
        <div className="mb-12">
          <h2 className="text-3xl mb-16 text-bold">Stardust Privacy Policy</h2>
          <h4 className="text-xl mb-4">
            YOUR PRIVACY IS CRITICALLY IMPORTANT TO US.
          </h4>
          <div className="w-auto bg-gray-100 p-2">
            <p>Stardust AI is located at:</p>
            <p>Stardust AI</p>
            <p>540 Howard Street San Francisco</p>
            <p>94105 - California , United States</p>
            <p>4152739525</p>
          </div>
        </div>

        <div className="mb-12">
          <p className="mb-8">
            It is Stardust AI's policy to respect your privacy regarding any
            information we may collect while operating our website. This Privacy
            Policy applies to https://stardust.ai/ (hereinafter, "us", "we", or
            "https://stardust.ai/"). We respect your privacy and are committed
            to protecting personally identifiable information you may provide us
            through the Website. We have adopted this privacy policy ("Privacy
            Policy") to explain what information may be collected on our
            Website, how we use this information, and under what circumstances
            we may disclose the information to third parties. This Privacy
            Policy applies only to information we collect through the Website
            and does not apply to our collection of information from other
            sources.
          </p>
          <p>
            This Privacy Policy, together with the Terms and conditions posted
            on our Website, set forth the general rules and policies governing
            your use of our Website. Depending on your activities when visiting
            our Website, you may be required to agree to additional terms and
            conditions.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">WEBSITE VISITORS</h4>
          <p className="mb-8">
            Like most website operators, Stardust AI collects
            non-personally-identifying information of the sort that web browsers
            and servers typically make available, such as the browser type,
            language preference, referring site, and the date and time of each
            visitor request. Stardust AI's purpose in collecting non-personally
            identifying information is to better understand how Stardust AI's
            visitors use its website. From time to time, Stardust AI may release
            non-personally-identifying information in the aggregate, e.g., by
            publishing a report on trends in the usage of its website.
          </p>
          <p>
            Stardust AI also collects potentially personally-identifying
            information like Internet Protocol (IP) addresses for logged in
            users and for users leaving comments on https://stardust.ai/ blog
            posts. Stardust AI only discloses logged in user and commenter IP
            addresses under the same circumstances that it uses and discloses
            personally-identifying information as described below.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">
            GATHERING OF PERSONALLY-IDENTIFYING INFORMATION
          </h4>
          <p>
            Certain visitors to Stardust AI's websites choose to interact with
            Stardust AI in ways that require Stardust AI to gather
            personally-identifying information. The amount and type of
            information that Stardust AI gathers depends on the nature of the
            interaction. For example, we ask visitors who sign up for a blog at
            https://stardust.ai/ to provide a username and email address.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">SECURITY</h4>
          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">ADVERTISEMENTS</h4>
          <p>
            Ads appearing on our website may be delivered to users by
            advertising partners, who may set cookies. These cookies allow the
            ad server to recognize your computer each time they send you an
            online advertisement to compile information about you or others who
            use your computer. This information allows ad networks to, among
            other things, deliver targeted advertisements that they believe will
            be of most interest to you. This Privacy Policy covers the use of
            cookies by Stardust AI and does not cover the use of cookies by any
            advertisers.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">LINKS TO EXTERNAL SITES</h4>
          <p className="mb-8">
            Our Service may contain links to external sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy and terms and conditions of every site you
            visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites,
            products or services.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">
            HTTPS://STARDUST.AI/ USES GOOGLE ADWORDS FOR REMARKETING
          </h4>
          <p className="mb-8">
            https://stardust.ai/ uses the remarketing services to advertise on
            third party websites (including Google) to previous visitors to our
            site. It could mean that we advertise to previous visitors who
            haven't completed a task on our site, for example using the contact
            form to make an enquiry. This could be in the form of an
            advertisement on the Google search results page, or a site in the
            Google Display Network. Third-party vendors, including Google, use
            cookies to serve ads based on someone's past visits. Of course, any
            data collected will be used in accordance with our own privacy
            policy and Google's privacy policy.
          </p>
          <p>
            You can set preferences for how Google advertises to you using the
            Google Ad Preferences page, and if you want to you can opt out of
            interest-based advertising entirely by cookie settings or
            permanently using a browser plugin.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">AGGREGATED STATISTICS</h4>
          <p>
            Stardust AI may collect statistics about the behavior of visitors to
            its website. Stardust AI may display this information publicly or
            provide it to others. However, Stardust AI does not disclose your
            personally-identifying information.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">COOKIES</h4>
          <p className="mb-8">
            To enrich and perfect your online experience, Stardust AI uses
            "Cookies", similar technologies and services provided by others to
            display personalized content, appropriate advertising and store your
            preferences on your computer.
          </p>
          <p className="mb-8">
            A cookie is a string of information that a website stores on a
            visitor's computer, and that the visitor's browser provides to the
            website each time the visitor returns. Stardust AI uses cookies to
            help Stardust AI identify and track visitors, their usage of
            https://stardust.ai/, and their website access preferences. Stardust
            AI visitors who do not wish to have cookies placed on their
            computers should set their browsers to refuse cookies before using
            Stardust AI's websites, with the drawback that certain features of
            Stardust AI's websites may not function properly without the aid of
            cookies.
          </p>
          <p>
            By continuing to navigate our website without changing your cookie
            settings, you hereby acknowledge and agree to Stardust AI's use of
            cookies.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">PRIVACY POLICY CHANGES</h4>
          <p>
            Although most changes are likely to be minor, Stardust AI may change
            its Privacy Policy from time to time, and in Stardust AI's sole
            discretion. Stardust AI encourages visitors to frequently check this
            page for any changes to its Privacy Policy. Your continued use of
            this site after any change in this Privacy Policy will constitute
            your acceptance of such change.
          </p>
        </div>

        <div className="mb-12">
          <h4 className="text-xl mb-4">CREDIT & CONTACT INFORMATION</h4>
          <p>
            This privacy policy was created at termsandconditionstemplate.com.
            If you have any questions about this Privacy Policy, please contact
            us via email or phone.
          </p>
        </div>
      </MainContainer>
      <Footer />
    </PageContainer>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query PrivacyPolicyPage($language: String!) {
    ...AllLangauages
  }
`;
